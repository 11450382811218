import React from 'react';
import { Wrapper } from '../DithcordStyles';

function ServerUsers({ server }) {
    // console.log('', '\n', '--------------SERVER USERS COMPONENT DATA--------------', '\n', '**DATA GOES HERE**', '\n', '')

    return (
        <div>
            <Wrapper>

            </Wrapper>
        </div>
    )
}


export default ServerUsers
